// config.js

const bizIcon = "https://handsucantrustconstruction.com/wp-content/uploads/2024/10/5d10d537ea1a17c565925db9ee85836f.webp";
const imageOne = "https://handsucantrustconstruction.com/wp-content/uploads/2024/11/No-BS-Remodeling-1-scaled-1-1536x998.jpeg";
const imageTwo = "https://handsucantrustconstruction.com/wp-content/uploads/2024/11/g6.webp";
const imageThree = "https://handsucantrustconstruction.com/wp-content/uploads/2024/11/g6.webp";
const imageFour = "https://handsucantrustconstruction.com/wp-content/uploads/2024/11/g4-1.webp";
const imageFive = "https://handsucantrustconstruction.com/wp-content/uploads/2024/11/g5.webp";

const socialMediaLink = 'https://cristobaldrywallllc.com/';

export const siteConfig = {
  title: "E. Arias & Co",
  metaDescription: "E. Arias & Co",
  theme: {
    primaryColor: "silver-500",
    secondaryColor: "gray-200",
    accentColor: "text-silver-500"
  },
  logo: {
    src: bizIcon,
    alt: "E. Arias & Co",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "E. Arias & Co",
    headline: "Building Trust, One Project at a Time",
    address: "San Francisco, United States",
    description: "At E. Arias & Co, we specialize in transforming spaces and turning dreams into reality. With a commitment to quality craftsmanship and reliability, we’re the construction partner you can trust to bring your vision to life.",
  },
  images: {
    business: {
      src: imageOne,
      alt: "Quality Construction Company",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
      description: 
        "At E. Arias & Co, expert craftsmanship is at the heart of everything we do. Our skilled team delivers precision and quality on every project, ensuring results that stand the test of time.",
    },
    {
      description: 
        "We offer comprehensive construction services, handling everything from planning to completion. No matter the project size or complexity, we’re equipped to bring your vision to life.",
    },
    {
      description: 
        "Our client-centered approach ensures seamless communication, reliable timelines, and a stress-free experience. At E. Arias & Co, your satisfaction is our top priority.",
    },
  ],
  services: {
    sectionTitle: "Our Services",
    description: 
      "At E. Arias & Co, we specialize in remodeling, renovation, and full construction services that breathe new life into your property. Whether you’re updating your home, expanding your space, or building from the ground up, we deliver exceptional quality and craftsmanship you can trust. With a client-centered approach and the use of premium materials, we ensure that every project meets your expectations and exceeds industry standards.",
    callouts: [
      {
        name: 'Renovation',
        description: 
          "Refresh and update your property with our expert renovation services, tailored to enhance both functionality and aesthetic appeal.",
        imageSrc: imageTwo,
        imageAlt: 'Professional contractor performing a home renovation',
      },
      {
        name: 'Remodeling',
        description: 
          "Transform your space with our custom remodeling services, designed to bring your vision to life with precision and style.",
        imageSrc: imageThree,
        imageAlt: 'Completed remodeling project with modern design elements',
      },
      {
        name: 'Full Construction',
        description: 
          "From foundation to finish, our full construction services provide a seamless experience for building your dream home or commercial property.",
        imageSrc: imageFour,
        imageAlt: 'Modern building under construction',
      }
    ],
    otherServices: [
      "Concrete Work",
      "Interior and Exterior Painting",
      "Project Management and Planning",
      "Custom Additions and Expansions",
    ],
  },  
  about: {
    sectionTitle: "About Us",
    description: "Cristobal Drywall LLC offers top-quality drywall services for residential and commercial clients in Orlando, Sanford, Apopka, Kissimmee, and nearby areas. With over 7 years of experience, we specialize in drywall installation, finishing, repairs, and custom solutions, ensuring every project is completed on time, within budget, and to the highest standards.",
    image: {
      src: imageFive,
      alt: "Cristobal Drywall team in action",
      width: 2432,
      height: 1442
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "San Francisco, United States",
    googleMapsEmbedUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.086497964073!2d-122.419415484681!3d37.77492977975995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085809c5b9b1b1b%3A0x4a0b8f1b1b1b1b1b!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=San%20Francisco,%20CA&ll=37.774929,-122.419415&q=San%20Francisco' 
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=San+Francisco,+CA&FORM=HDRSC6' 
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/San+Francisco,+CA/@37.774929,-122.419415,15a,814.57589625d,35y,0h,0t,0r' 
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/San+Francisco,+CA/@37.774929,-122.419415,15z' 
      }
    ]
  },
  contact: {
    sectionTitle: "Ready to Start Your Project?",
    description: "Let’s turn your vision into reality! Whether you’re planning a remodel, renovation, or full construction, our team at E.Arias & Co. is here to help. Contact us today for a free consultation, and let’s discuss how we can bring your ideas to life with quality and expertise.",
    socialLinks: {
      facebook: socialMediaLink,
      instagram: socialMediaLink,
      twitter: socialMediaLink,
      youtube: socialMediaLink,
      tiktok: socialMediaLink
    },
    contactNumbers: ["415-573-7637"],
    logo: bizIcon,
    emailRecipient: "hands.u.cantrust@gmail.com"
  },
  footer: {
    bizSolutionsLogo: "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg"
  }
};
